/* CourseAnalytics.module.css */

.contentWrapper {
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .customCard {
    border: none;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  .customCardHeader {
    background-color: #f7f7f7;
    border-bottom: none;
  }
  
  .customCardTitle {
    margin: 0;
  }
  
  .customCardBody {
    padding: 20px;
  }
  
  .statCard {
    text-align: center;
    padding: 20px;
    background-color: #fafafa;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .statIcon {
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .infoCard {
    text-align: center;
    padding: 15px;
    background-color: #f0f8ff;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .infoIcon {
    color: #17a2b8;
    margin-bottom: 5px;
  }
  
  .chartContainer {
    margin-bottom: 20px;
  }
  
  .topicCard {
    text-align: center;
    padding: 20px;
    background-color: #fff5e6;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .easyIcon {
    color: #28a745;
    margin-right: 5px;
  }
  
  .hardIcon {
    color: #dc3545;
    margin-right: 5px;
  }
  
  .footer {
    margin-top: 20px;
  }
  