  .shine {
    position: relative;
    display: inline-block;
    color: #d4af37; /* Gold color */
    font-weight: bold;
    cursor: pointer;
    overflow: hidden;
  }
  
  .shine::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%);
    
    /* Combined duration and delay into the animation */
    animation: shine 5s ease-in-out infinite; /* Adjust the total duration to include the delay */
  }
  
  @keyframes shine {
    0% {
      left: -100%;
    }
    40% {
      left: 100%;
    }
    100% {
      left: 100%; /* Pause at the end for the delay */
    }
  }
  