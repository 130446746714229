.college-comparison {
    font-family: Arial, sans-serif;
  }
  
  .search-card {
    max-width: 600px;
    margin: 2rem auto;
  }
  
  .suggestion-list {
    position: absolute;
    background-color: white;
    border: 1px solid #ced4da;
    border-top: none;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    z-index: 1000;
  }
  
  .suggestion-item {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f8f9fa;
  }
  
  .selected-college {
    display: inline-block;
    padding: 0.5rem 1rem;
    margin: 0.25rem;
    background-color: #506fd9;
    color: white;
    border-radius: 20px;
    font-weight: 500;
  }
  
  .remove-btn {
    background: none;
    border: none;
    color: white;
    margin-left: 0.5rem;
    cursor: pointer;
    font-weight: bold;
  }
  
  .comparison-view {
    display: flex;
    flex-direction: column;
  }
  
  .overall-fit-section {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 1rem;
    padding: 1rem;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
  }
  
  .recommendation {
    grid-column: 1 / 2;
  }
  
  .overall-fit-chart {
    grid-column: 2 / 3;
    height: 300px;
  }
  
  .category-weights {
    grid-column: 3 / 4;
  }
  
  .weight-slider {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .weight-slider label {
    flex: 1;
  }
  
  .weight-slider input {
    flex: 2;
    margin: 0 0.5rem;
  }
  
  .weight-slider span {
    flex: 0 0 40px;
    text-align: right;
  }
  
  .detailed-comparison {
    display: flex;
    overflow-x: auto;
  }
  
  .college-column {
    flex: 1 0 300px;
    border-right: 1px solid #dee2e6;
    padding: 1rem;
  }
  
  .college-column:last-child {
    border-right: none;
  }
  
  .college-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #506fd9;
  }
  
  .fit-section {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #dee2e6;
  }
  
  .fit-section h5 {
    margin-bottom: 1rem;
    color: #506fd9;
  }

  .recommendation-details {
    background-color: #f8f9fa;
    border-radius: 0.25rem;
    padding: 1rem;
    margin-top: 1rem;
  }
  
  .recommendation-details h6 {
    color: #506fd9;
    margin-bottom: 0.5rem;
  }
  
  .recommendation-details .text-primary {
    color: #506fd9 !important;
  }

  .text-success-emphasis {
    color: #28a745 !important;  /* Green */
    font-weight: bold;
  }
  
  .text-danger-emphasis {
    color: #dc3545 !important;  /* Red */
  }
  
  /* For dark mode compatibility */
  @media (prefers-color-scheme: dark) {
    .text-success-emphasis {
        color: #5cb85c !important;  /* Adjusted green for dark mode */
        font-weight: bold;
    }

    .text-danger-emphasis {
        color: #d9534f !important;  /* Adjusted red for dark mode */
    }
  }