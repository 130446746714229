/* CoursesPage.css */

.main-title {
  font-weight: 600;
  font-size: 24px;
  color: #343a40;
  margin-bottom: 0;
  line-height: 1.4;
}

.card-course-list {
  height: 100%;
  border-width: 0;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-course-list .card-header {
  border-bottom-color: rgba(204, 204, 204, 0.55);
  background-color: transparent;
  padding: 15px;
  display: flex;
  align-items: center;
}

.card-course-list .card-title {
  font-weight: 600;
  font-size: 15px;
  color: #343a40;
  margin-bottom: 0;
  line-height: 1.4;
}

.card-course-list .card-body {
  padding: 15px;
  position: relative;
}

.course-list-body {
  display: flex;
  flex-direction: column;
}

.course-grid {
  display: flex;
  flex-wrap: wrap;
}

.course-card {
  flex: 1 1 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  transition: box-shadow 0.3s;
}

.course-card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.mastery-course {
  box-shadow: 0 0 10px gold !important;
}

.mastery-course .course-card {
  border-color: gold;
}

.course-header {
  position: relative;
}

.card-summary {
  border-width: 0;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-summary .card-header {
  border-bottom-color: rgba(204, 204, 204, 0.55);
  background-color: transparent;
  padding: 15px;
  display: flex;
  align-items: center;
}

.card-summary .card-title {
  font-weight: 600;
  font-size: 15px;
  color: #343a40;
  margin-bottom: 0;
  line-height: 1.4;
}

.card-summary .card-body {
  padding: 15px;
  position: relative;
}

@media (max-width: 768px) {
  .course-card {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  .course-card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.tooltip-inner {
  opacity: 0.85 !important;
}

.course-progress-text {
  font-size: 14px;
  color: #6c757d;
  margin-left: 8px;
  font-weight: bold;
}

.mastery-crown {
  font-size: 18px;      /* Adjust size as needed */
  color: gold;          /* Sets the color to gold */
  margin-left: 8px;     /* Adds space between title and icon */
  vertical-align: middle; /* Ensures alignment with text */
}
