.main-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure it takes the full height of the viewport */
  overflow: hidden;
  padding-bottom: 20px; /* Ensure footer doesn't overlap */
}

.main-app .row {
  flex: 1; /* Allow the row to take up available space */
  display: flex;
  flex-wrap: wrap;
}

.card-one {
  flex: 1 1 auto; /* Ensure card takes available space */
  margin-bottom: 1rem; /* Add bottom margin to separate cards */
}

.card-one .card-body {
  overflow: auto;
}

.form-control-textarea-lg {
  height: 600px; /* Adjust height as needed */
}

.overflow-auto {
  max-height: 150px; /* Adjust max-height as needed */
  overflow: auto;
}

/* Ensure footer stays at the bottom */
footer {
  position: relative;
  bottom: 0;
  width: 100%;
  flex-shrink: 0; /* Ensure footer doesn't shrink */
  margin-top: auto; /* Push footer to the bottom */
}

.highlight-red {
  border-radius: 1em 0 1em 0;
  background-image: linear-gradient(
    -100deg,
    rgba(255, 20, 0, 0.2),
    rgba(255, 20, 0, 0.7) 95%,
    rgba(255, 20, 0, 0.1)
  );
}

.highlight-yellow {
  border-radius: 0em 0 0em 0;
  background-image: linear-gradient(
    -100deg,
    rgba(255, 224, 0, 0.2),
    rgba(255, 224, 0, 0.7) 95%,
    rgba(255, 224, 0, 0.1)
  );
}

.highlight-green {
  border-radius: 0em 0 0em 0;
  background-image: linear-gradient(
    -100deg,
    rgba(0, 255, 20, 0.2),
    rgba(0, 255, 20, 0.7) 95%,
    rgba(0, 255, 20, 0.1)
  );
}

.highlight-blue {
  border-radius: 1em 0 1em 0;
  background-image: linear-gradient(
    -100deg,
    rgba(0, 20, 255, 0.2),
    rgba(0, 20, 255, 0.7) 95%,
    rgba(0, 20, 255, 0.1)
  );
}

.essay-text {
  font-size: 14px;
}

.comment-tooltip {
  max-width: 300px;
  font-size: 14px;
  line-height: 1.4;
}

.tooltip-inner {
  background-color: #f8f9fa;
  color: #333;
  border: 1px solid #dee2e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.bs-tooltip-top .arrow::before {
  border-top-color: #dee2e6;
}

.highlight-green:hover,
.highlight-yellow:hover,
.highlight-red:hover {
  cursor: pointer;
  opacity: 0.8;
}

.analysis-text {
  flex-grow: 1;
  overflow-wrap: anywhere; /* Allow text to wrap naturally */
}

.button-container {
  display: flex;
  flex-shrink: 0;
  margin-left: 10px; /* Add some space between text and buttons */
}

.list-group-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the start of the container */
}


.essay-form-wrapper .essay-upgrade-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  z-index: 1; /* Ensure the overlay is above other content */
}

.essay-form-wrapper .essay-upgrade-button {
  font-size: 14px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}


.essay-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adds vertical spacing between the child elements */
}


.custom-progress-wrapper .credits-progress-bar {
  height: 100%;
}

.custom-progress-wrapper .credits-progress-bar .progress-bar {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 13px !important; /* Increase font size */
  line-height: 18px !important; /* Ensure the text is centered vertically */
}

.no-credits-message {
  color: rgb(0, 0, 0); /* Example: you can change the color */
  font-weight: bold;
  text-align: center;
}
