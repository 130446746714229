.overlay-upgrade-userdash {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  
  .overlay-content-userdash {
    text-align: center;
  }
  


  .college-search-bar {
    transition: all 0.3s ease-in-out;
    position: relative;
    margin-bottom: 20px;
  }
  
  .college-search-bar-fixed {
    position: fixed;
    top: 10px;
    width: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 100;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .college-details-container {
    margin-top: 50px; /* Space to accommodate the fixed search bar */
  }
  
  .college-details {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
  }