/* Scrollbar */
.academic-social-home-main ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* Animations */
  @keyframes academic-social-home-fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes academic-social-home-slideIn {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  /* Apply animations */
  .academic-social-home-card-post, .academic-social-home-collaboration-item, .academic-social-home-challenge-item {
    animation: academic-social-home-fadeIn 0.5s ease-out, academic-social-home-slideIn 0.5s ease-out;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .academic-social-home-custom-tabs {
      flex-direction: column;
    }
  
    .academic-social-home-tab-button {
      border-bottom: 1px solid #dee2e6;
    }
  
    .academic-social-home-tab-button:last-child {
      border-bottom: none;
    }
  }
  
  /* Enhanced Button Styles */
  .academic-social-home-btn-primary, .academic-social-home-btn-outline-primary {
    border-radius: 20px;
    padding: 8px 16px;
    transition: all 0.3s ease;
  }
  
  .academic-social-home-btn-primary:hover, .academic-social-home-btn-outline-primary:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3);
  }
  
  /* Card Header Styles */
  .academic-social-home-card-header {
    background: linear-gradient(90deg, #f8f9fa, #e9ecef);
    border-bottom: none;
  }
  
  .academic-social-home-card-title {
    font-weight: 600;
    color: #495057;
  }
  
  /* Avatar Enhancements */
  .academic-social-home-avatar {
    border: 2px solid #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .academic-social-home-avatar:hover {
    transform: scale(1.1);
  }
  
  /* Badge Styles */
  .academic-social-home-badge {
    font-weight: 500;
    padding: 6px 10px;
    border-radius: 12px;
  }
  
  /* Form Control Enhancements */
  .academic-social-home-form-control, .academic-social-home-form-select {
    border-radius: 8px;
    border: 1px solid #ced4da;
    transition: all 0.3s ease;
  }
  
  .academic-social-home-form-control:focus, .academic-social-home-form-select:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    border-color: #80bdff;
  }
  
  /* Link Styles */
  .academic-social-home a {
    color: #007bff;
    transition: all 0.3s ease;
  }
  
  .academic-social-home a:hover {
    color: #0056b3;
    text-decoration: none;
  }
  
  /* Progress Bar Enhancements */
  .academic-social-home-progress {
    height: 10px;
    border-radius: 5px;
    background-color: #e9ecef;
  }
  
  .academic-social-home-progress-bar {
    background-color: #007bff;
    border-radius: 5px;
  }
  
  /* Custom Card Footer */
  .academic-social-home-card-footer {
    background-color: #f8f9fa;
    border-top: none;
  }
  
  /* Hover effect for list items */
  .academic-social-home-list-unstyled li {
    transition: all 0.3s ease;
  }
  
  .academic-social-home-list-unstyled li:hover {
    background-color: #f8f9fa;
    border-radius: 5px;
    padding-left: 5px;
  }



.academic-social-home-tab-button {
    background-color: rgba(80, 111, 217, 0.1); /* Light background for inactive tabs */
    color: rgba(80, 111, 217, 0.7); /* Light text color for inactive tabs */
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 30px;
    transition: all 0.3s ease;
    margin-right: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.academic-social-home-tab-button:hover {
    background-color: rgba(80, 111, 217, 0.3); /* Darken background on hover */
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 123, 255, 0.3);
}

.academic-social-home-tab-button.active {
    background-color: #506fd9; /* Full color for active tab */
    color: #fff; /* White text for active tab */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
}
