/* TestResult.css */

.question-item.correct {
  border-left: 5px solid #28a745;
}

.question-item.incorrect {
  border-left: 5px solid #dc3545;
}

.question-item .icon {
  font-size: 1.5rem;
}

.question-item ul {
  list-style-type: none;
  padding-left: 0;
}

.question-item ul li {
  margin-bottom: 0.5rem;
}
