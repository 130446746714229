.guide-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.guide-content {
  flex: 1;
  margin-right: 2rem; /* Adjust margin as needed */
}

.guide-title {
  font-weight: 500;
  color: #333;
  margin-bottom: 2rem;
}

.guide-card {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.guide-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.guide-card-header {
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  padding: 1rem 1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.guide-section-title {
  color: #333;
  font-weight: 600;
  margin: 0;
  
}

.guide-subsection-title {
  font-size: 1rem;
  font-weight: 600;
  color: #444;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #f0f0f0;
}

.guide-article-list {
  list-style-type: none;
  padding: 0;
}

.guide-article-link {
  display: block;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 6px;
  text-decoration: none;
  color: inherit;
  margin-bottom: 0.75rem;
  flex-grow: 1;
  transition: all 0.3s ease;
  border: 1px solid #f0f0f0;
}

.guide-article-link:hover {
  background-color: #f8f9fa;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.guide-article-title {
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.25rem;
}

.guide-article-description {
  font-size: 0.875rem;
  color: #6c757d;
  margin-bottom: 0;
}

.guide-sidebar {
  width: 300px; /* Set a fixed width for the sidebar */
  flex-shrink: 0;
}



.guide-sidebar-card {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  position: sticky;
  top: 0; /* Keep the sidebar fixed on scroll if needed */
}

.guide-sidebar-title {
  font-size: 1.3rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 1rem;
  
}

.guide-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.guide-tags .badge {
  font-size: 0.8rem;
  padding: 0.5em 0.8em;
  border-radius: 20px;
  background-color: #f8f9fa;
  color: #333;
  border: 1px solid #e9ecef;
  transition: all 0.3s ease;
}

.guide-tags .badge:hover {
  background-color: #e9ecef;
  transform: translateY(-1px);
}

.guide-tags .badge svg {
  margin-right: 0.25rem;
}

@media (max-width: 768px) {
  .guide-title {
    font-size: 2rem;
  }

  .guide-card-header {
    padding: 0.75rem 1rem;
  }

  .guide-section-title {
    font-size: 1.1rem;
  }

  .guide-article-link {
    padding: 0.75rem;
  }

  .guide-article-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.75rem;
}

.guide-article-link {
    flex-grow: 1;
    margin-right: 1rem;
}

.guide-article-read-status {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  flex-shrink: 0;
}

.guide-article-read-status svg {
    color: #ffffff;
    transition: all 0.3s ease;
}

.guide-article-read-status.read {
  background-color: #28a745;
  border-color: #28a745;
}

.guide-article-read-status:hover {
    transform: scale(1.1);
}

.guide-article-read-status.read svg {
  color: #28a745;  /* Green icon when read */
}


}