.app-analyzer-main {
  background-color: #f8f9fa;
}

.app-analyzer-score-card,
.app-analyzer-overview-card,
.app-analyzer-academic-card,
.app-analyzer-test-scores-card,
.app-analyzer-activities-card,
.app-analyzer-major-compatibility-card {
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.app-analyzer-score-card:hover,
.app-analyzer-overview-card:hover,
.app-analyzer-academic-card:hover,
.app-analyzer-test-scores-card:hover,
.app-analyzer-activities-card:hover,
.app-analyzer-major-compatibility-card:hover {
  transform: translateY(-0px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.app-analyzer-card-title {
  margin-bottom: 1rem;
}

.app-analyzer-score-circle {
  width: 200px;
  height: 200px;
  margin-right: 1rem;
}

.app-analyzer-nps-text {
  margin-top: 5px;
  text-align: center;
}

.app-analyzer-overview-text,
.app-analyzer-subtitle {
  margin-bottom: 1rem;
}

.app-analyzer-list {
  padding-left: 1.5rem;
}

.app-analyzer-academic-stats,
.app-analyzer-test-scores {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.app-analyzer-stat-item,
.app-analyzer-test-item {
  display: flex;
  flex-direction: column;
}

.app-analyzer-test-score-display {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.app-analyzer-activities-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.app-analyzer-activities-title {
  display: flex;
  align-items: center;
}

.app-analyzer-activities-title h6 {
  margin: 0;
  margin-left: 10px;
}

.app-analyzer-check-icon {
  color: #4CAF50;
  font-size: 24px;
}

.app-analyzer-activities-actions {
  color: #4285F4;
}

.app-analyzer-show-less {
  cursor: pointer;
}

.app-analyzer-activities-subheader {
  margin-bottom: 15px;
}

.app-analyzer-edit-icon {
  color: #4285F4;
  margin-left: 5px;
  cursor: pointer;
}


.app-analyzer-strength-bar {
  height: 8px;
  background-color: #4285F4;
  border-radius: 4px;
}

.app-analyzer-major-item {
  margin-bottom: 1.5rem;
}



.app-analyzer-strength-bar {
  height: 6px;
  background-color: #4285F4;
  border-radius: 3px;
}

.table-hover th, .table-hover td {
  padding: 12px 15px; /* Add consistent padding for cells */
  vertical-align: middle; /* Align text vertically in the center */
}

.table-hover th {
  text-align: left; /* Align headers to the left */
  font-weight: bold; /* Make header text bold */
}

.table-hover td {
  text-align: left; /* Align text to the left */
}

.table-hover td .strength-bar {
  height: 10px;
  background-color: #4CAF50; /* Color for the strength bar */
  border-radius: 5px;
}

.strength-column {
  text-align: center;
}

.strength-bar {
  width: 100%; /* Full width for the background of the bar */
  height: 8px; /* Height of the bar */
  background-color: #ddd; /* Background color for the bar */
  border-radius: 4px; /* Rounded corners */
  position: relative; /* To contain the fill inside */
}

.strength-fill {
  height: 100%; /* Ensure the fill takes full height */
  background-color: #4CAF50; /* Green color for the strength fill */
  border-radius: 4px; /* Rounded corners for the fill */
  transition: width 0.3s ease; /* Smooth transition for the bar */
}

