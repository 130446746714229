.college-list-body {
    display: flex;
    flex-direction: column;
  }
  

  .card-college-list {
    height: 100%;
    border-width: 0;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Equivalent to shadow-dreamy($gray-400) */
  
    .card-header {
      border-bottom-color: rgba(204, 204, 204, 0.55); /* Replace $gray-300 */
      background-color: transparent;
      padding: 15px;
      display: flex;
      align-items: center;
    }
  
    .card-title {
      font-weight: 600; /* Replace $font-weight-semibold */
      font-size: 15px;
      color: #343a40; /* Replace $dark */
      margin-bottom: 0;
      line-height: 1.4;
    }
  
    .nav-icon {
      margin-right: -4px;
    }
  
    .nav-icon .nav-link {
      opacity: 0.6;
    }
  
    .nav-icon .nav-link:hover {
      opacity: 1;
    }
  
    .nav-pills {
      font-size: 14px; /* Replace $font-size-sm */
    }
  
    .nav-pills .nav-link {
      border-radius: 3px;
      color: #6c757d; /* Replace $secondary */
      padding: 4px 15px;
    }
  
    .nav-pills .nav-link.active {
      background-color: #cccccc; /* Replace $gray-300 */
      color: #495057; /* Replace $gray-700 */
    }
  
    .card-body {
      padding: 15px;
      position: relative;
    }
  
    small {
      color: #6c757d; /* Replace $secondary */
      font-family: Arial, sans-serif; /* Replace $font-family-sans-serif */
      font-size: 12px;
    }
  
    .legend {
      margin: 0;
      padding: 0;
      list-style: none;
      line-height: 1.25;
    }
  
    .legend li {
      display: inline-flex;
      align-items: center;
      position: relative;
      font-size: 12px; /* Replace $font-size-xs */
    }
  
    .legend li::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin-right: 5px;
    }
  
    .legend li + li {
      margin-left: 10px;
    }
  
    .legend li:first-child::before {
      background-color: #506fd9;
    }
  
    .legend li:nth-child(2)::before {
      background-color: #dbdffd;
    }
  
    .legend li:last-child::before {
      background-color: #85b6ff;
    }
  
    .card-icon {
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 4px;
    }
  
    .card-icon i {
      font-size: 24px;
      line-height: 1;
    }
  
    .people-item {
      padding: 10px 15px;
    }
  
    .people-item + .people-item {
      margin-top: 0;
      border-top: 1px solid #e9ecef; /* Replace $gray-200 */
    }
  
    .people-item .avatar {
      margin-right: 8px;
    }
  
    .people-item .nav-link + .nav-link {
      margin-left: 5px;
    }
  
    .card-footer {
      padding: 15px;
      background-color: transparent;
      border-top-color: #e9ecef; /* Replace $gray-200 */
    }
  
    .list-group-item {
      border-color: #e9ecef; /* Replace $gray-200 */
    }
  }
  