.card-one {
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  .list-group-item {
    transition: background-color 0.3s;
  }
  
  .list-group-item:hover {
    background-color: #f8f9fa;
  }
  
  .ht-350 {
    height: 350px;
  }
  
  .table th {
    font-weight: 600;
  }
  
  .progress {
    height: 5px;
  }



  .table tbody tr {
    border-bottom: 1px solid #dee2e6; /* Ensures the row border remains consistent */
}

.table tbody td {
    vertical-align: middle; /* Ensures content is vertically aligned in the middle */
    border-bottom: none; /* Remove default border-bottom to avoid double borders */
}

.btn-list {
    margin-left: 5px; /* Adjusts the margin between buttons to avoid overlap */
}











/* Ensure styles are scoped specifically to the CollegeSearch page */
.card-one.position-relative {
  position: relative;
  overflow: hidden;
}

.card-one .college-search-upgrade-overlay {
  position: absolute;
  top: calc(50% + 20px); /* Adjusted from 50% to account for the header height */
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 60px); /* Subtracting height of the title (Card.Header) */
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;
}

.card-one .college-search-upgrade-button {
  font-size: 14px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

/* Make sure that the header isn't blurred */
.card-one .card-header {
  z-index: 3;
  position: relative;
  filter: none !important;  /* Ensure header stays clear */
  pointer-events: all !important;
}

.card-one .card-body.blurred-content {
  filter: blur(8px);
  pointer-events: none;
}
