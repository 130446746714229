/* Custom Pricing Toggle Wrapper */
.custom-pricing-toggle-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
}

/* Custom Pricing Toggle */
.custom-pricing-toggle {
    display: flex;
    align-items: center;
    font-size: 16px;
    background-color: #f8f9fa;
    border-radius: 30px;
    padding: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.custom-pricing-toggle span {
    cursor: pointer;
    padding: 8px 15px;
    color: #6c757d;
    transition: color 0.3s ease;
}

.custom-pricing-toggle .active {
    color: #000;
    font-weight: bold;
}

/* Custom Toggle Switch */
.custom-toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;
    margin: 0 8px;
}
.custom-toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.custom-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.custom-slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

input:checked + .custom-slider {
    background-color: #ffa500;
}

input:checked + .custom-slider:before {
    transform: translateX(22px);
}

/* Discount Note Below the Toggle */
.custom-pricing-discount-note {
    font-size: 14px;
    color: #000000;
    margin-top: 10px;
}