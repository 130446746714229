.free-trial-badge {
    display: inline-flex;
    align-items: center;
    padding: 6px 12px;
    background-color: #efbf04;
    color: black;
    font-size: 13px;
    font-weight: bold;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .free-trial-badge:hover {
    background-color: #efbf04;
  }

  .credit-popover {
    background-color: #efbf04;
    border: none;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    color: white;
    max-width: 300px;
  }
  
  .credit-popover .popover-body {
    padding: 20px;
  }
  
  .credit-popover .popover-arrow::before {
    border-bottom-color: #efbf04;
  }
  
  .credit-popover h3 {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  
  .credit-info {
    margin-bottom: 15px;
  }
  
  .credit-info div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .credit-info strong {
    font-size: 1.1rem;
  }
  
  .credit-popover p {
    font-size: 0.9rem;
    margin-bottom: 0;
  }
  
  .credit-popover a {
    color: #ffffff;
    text-decoration: underline;
  }