
/* Article Content */
.article-content {
    font-size: 1rem;
    line-height: 1.8;
    padding: 2rem 0;
}

.article-content p {
    margin-bottom: 1.5rem;
}

/* Article Sidebar */
.article-sidebar {
    background-color: #f8f9fa;
    border: none;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 1.5rem;
}

.article-sidebar h5 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.article-sidebar p {
    font-size: 0.95rem;
    margin-bottom: 0.5rem;
}

.article-tags .badge {
    font-size: 0.85rem;
    padding: 0.4rem 0.8rem;
}

.related-articles ul {
    list-style-type: none;
    padding-left: 0;
    font-size: 0.95rem;
}

.related-articles ul li {
    margin-bottom: 0.5rem;
}

/* Responsive Styles */
@media (max-width: 767.98px) {
    .article-content {
        padding: 1.5rem 0;
    }

    .article-sidebar {
        margin-top: 2rem;
    }
}