/* TestCreation.css */

.test-creation h1 {
    color: #34495e;
  }
  
  .test-creation form {
    display: flex;
    flex-direction: column;
  }
  
  .test-creation label {
    margin-bottom: 1em;
  }
  
  .test-creation button {
    width: 100px;
  }
  