.no-notifications-head123 {
    text-align: center;
    padding: 20px;
    color: #777;
  
    i {
      display: block;
      margin: 0 auto;
    }
  
    p {
      font-size: 14px;
      margin: 10px 0 5px;
    }
  
    span {
      font-size: 12px;
      color: #aaa;
    }
  }
  