.notifications-page .notifications-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.notifications-page .notification-item {
    background-color: #ffffff; /* Set background to white */
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhance the shadow for a more pronounced effect */
}

.notifications-page .notification-icon {
    font-size: 1.5rem;
    color: #007bff;
    margin-right: 1rem;
}

.notifications-page .notification-content {
    flex-grow: 1;
}

.notifications-page .notification-content p {
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.notifications-page .notification-content span {
    font-size: 0.875rem;
    color: #6c757d;
}

.notifications-page .no-notifications {
    text-align: center;
    padding: 2rem 0;
}

.notifications-page .no-notifications .alert {
    background-color: #f8f9fa;
    border-color: #e9ecef;
    color: #495057;
}

.notifications-page .no-notifications .alert i {
    margin-right: 0.5rem;
}
