/* CourseDetailsPage.module.css */

.mainTitle {
  font-weight: 600;
  font-size: 24px;
  color: #343a40;
  margin-bottom: 0;
  line-height: 1.4;
}

.contentWrapper {
  margin-top: 20px;
}

.customCard {
  border-width: 0;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.customCardHeader {
  border-bottom-color: rgba(204, 204, 204, 0.55);
  background-color: transparent;
  padding: 15px;
  display: flex;
  align-items: center;
}

.customCardTitle {
  font-weight: 600;
  font-size: 18px;
  color: #343a40;
  margin-bottom: 0;
  line-height: 1.4;
}

.customCardBody {
  padding: 15px;
}

.noteActions button {
  margin-right: 5px;
}

.listGroupItem {
  border-color: #e9ecef;
}

.customBadge {
  font-size: 14px;
}

.footer {
  margin-top: 20px;
}

/* Quick Actions */
.quickActionsTitle {
  font-size: 28px;
  font-weight: bold;
}

.quickActionsButton {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .contentWrapper {
    margin-top: 10px;
  }

  .quickActionsTitle {
    font-size: 24px;
  }
}

.buttonIconText {
  display: inline-flex;
  align-items: center;
  gap: 5px; /* Space between icon and text */
  padding: 7px 10px; /* Adjust padding as needed */
}

.mb2 {
  margin-bottom: 8px;
}

.examDateContainer span,
.examDateContainer button {
  line-height: 1.5; /* Adjust this value as needed */
}

.streakBadge {
  display: inline-flex;
  align-items: center;
  background: linear-gradient(45deg, #FFC107, #FF9800); /* Yellow to Orange gradient */
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
}
