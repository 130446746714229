.custom-switch-college .form-check-input:checked {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .custom-switch-scholarships .form-check-input:checked {
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .custom-switch-state .form-check-input:checked {
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .custom-switch-federal .form-check-input:checked {
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  


  .app-calendar-page .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    flex-direction: column;
  }
  
  .app-calendar-page .spinner-border {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
  }
  