/* SidebarCustomStyles.css */

/* Targeting only .nav.ungroupedside */
.nav.ungroupedside {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  .nav.ungroupedside .nav-item {
    margin-bottom: 5px; /* Reduced spacing between items */
  }
  
  .nav.ungroupedside .nav-link {
    display: flex;
    align-items: center;
    padding: 8px 20px; /* Consistent padding */
    color: var(--bs-gray-700);
    font-weight: var(--bs-nav-link-font-weight, normal);
    text-decoration: none;
  }
  
  .nav.ungroupedside .nav-link:hover {
    color: var(--bs-dark);
  }
  
  .nav.ungroupedside .nav-link.active {
    color: var(--bs-primary);
    font-weight: var(--bs-font-weight-medium);
    /* Background color removed */
    background-color: transparent;
    border-radius: 0;
  }
  
  .nav.ungroupedside .nav-link i {
    margin-right: 12px; /* Consistent spacing between icon and text */
    font-size: 20px; /* Consistent icon size */
    width: 18px;
    opacity: .85;
    vertical-align: middle; /* Center the icon vertically */
  }
  
  /* Adding some margin to the top to space out from other sections */
  .nav.ungroupedside .nav-item:first-child {
    margin-top: 10px; /* Adjust spacing from the top section */
  }
  
  /* Adjust the margin between the Dashboard and College Resources */
  .nav.ungroupedside + .nav-group {
    margin-top: -5px; /* Reduce spacing between Dashboard and College Resources */
  }
  