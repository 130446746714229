.post-bar-container {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 10px;
  transition: all 0.3s ease;
}

.post-bar {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: text;
}

.post-bar-item {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  position: relative; /* Ensure the textarea is positioned absolutely within this container */
}

.post-bar-item i {
  margin-right: 10px;
  color: #6c757d;
  flex-shrink: 0;
}

.post-bar-item .textarea-container {
  flex-grow: 1;
  position: relative;
  min-height: 24px; /* Height of one line of text */
  width: 100%; /* Ensure it takes the full width of the container */
}

.post-bar-item textarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: none;
  background: transparent;
  font-size: 14px;
  color: #495057;
  resize: none;
  line-height: 1.5;
  padding: 0;
  margin: 0;
  outline: none;
  overflow: hidden;
}

.post-actions {
  display: flex;
  gap: 15px;
}

.post-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 10px;
}
